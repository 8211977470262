



































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { FiatType } from '@/api-svc-types';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import { SubTree } from '@/utils/subTree';

import { ApiSvcFiat, ApiSvcSubsidiary } from '../../../../generated/api-svc';
import { BaseVue } from '../../../BaseVue';
@Component({
  components: {
    UiDataTable,
  },
})
export default class SubsidiariesList extends BaseVue {
  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ required: true })
  public subsidiaries!: ApiSvcSubsidiary[];

  public get subs() {
    if (this.subsidiaries && this.subsidiaries.length > 0) {
      const flat = SubTree.build(this.subsidiaries);
      return flat;
    } else {
      return [];
    }
  }

  public multiCurrencySubsidiariesEnabled = this.checkFeatureFlag('multi-currency-subsidiaries');

  public headers = [
    {
      id: 'name',
      label: 'Name',
      defaultVisibility: true,
    },
    {
      id: 'id',
      label: 'Id',
      defaultVisibility: true,
    },
    {
      id: 'baseCurrency',
      label: 'Base Currency',
      defaultVisibility: this.multiCurrencySubsidiariesEnabled,
    },
  ];

  public formatFiatCurrency(currency?: ApiSvcFiat): string {
    if (!currency) {
      // Fall back to org's base currency
      currency = this.$store.state.currentOrg.baseCurrency;
      if (!currency) return '$';
    }

    // At this point we know currency is defined since we handled the null case above
    const currencyValue = currency as ApiSvcFiat;
    const fiats = this.$store.state.fiats.fiats as FiatType[] | undefined;
    const fiat = fiats?.find((f) => f.id === currencyValue.toString());
    return fiat?.name || currencyValue.toString();
  }

  public classFromOffset(offset: number) {
    if (offset === 0) {
      return '';
    } else {
      return `pl-${offset * 2}`;
    }
  }
}
